@charset "utf-8";

/*---------------------------------
	bit Common CSS 2018.2
	CSS inoue Document 2018.02
	Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Amita&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700&display=swap');

/*####################　共通設定　####################*/
@media all and (-ms-high-contrast:none){
  #MainImg h2 span{
    background: none!important;
    color: #266db4;
  }
  #PageRecruit #MainImg h2 span{
    color: #ed6325;
  }
  #PageContact #MainImg h2 span{
    color: #f1b140;
  }
  #PageRecruit.pageIndex #Main #ContBox02 .contSubBox02 .innerBasic h4{
    background: none!important;
    color: #ed6325;
  }
}
select::-ms-expand {
    display: none;
}


*{
  box-sizing:border-box;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both;
}

body {
  line-height: 1.5;
  text-align: center;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  /* font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif; */
  /* font-family: helvetica,'arial black',arial,sans-serif; */
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  letter-spacing: 0.1em;
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  font-weight: 500;
  /*font-weight: 500;  ※游明朝の時は400*/
  color: #222222;
  overflow-x: hidden;
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  text-decoration: none;
  display:block;
  color: #222;
  &:hover {
    text-decoration: none;
  }
}

body:not(#PageNews) img{
  width: 100%;
}

span{
  display: block;
}

input,select,textarea{
  border-radius:0;
}

hr, .showBox {
  display: none;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Main {
  min-height: 500px;
  _height: 500px;
}

#Footer {
  margin: 0 auto;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}

.btn01{
  font-weight: bold;
  display: block;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(-90deg, #f09937, #f3cb49);
  width: 180px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 25px;
  position: relative;
  &::before {
    content: "";
    display: block;
    background: url(../img/header/icon_04.png) 0 0 no-repeat;
    width: 23px;
    height: 18px;
    background-size: 23px auto;
    position: absolute;
    left: 45px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  span{
    color: #fff;
    padding-left: 25px;
  }
}


.more{
  margin-top: 30px;
  display: inline-block;
  border: 1px solid;
  border-radius: 25px;
  box-sizing: border-box;
  width: 170px;
  height: 50px;
  position: relative;
  &::before{
    content: "";
    display: block;
    width: 1px;
    height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    margin: auto;
  }
  a{
    line-height: 50px;
    height: 100%;
    font-size: 12px;
    letter-spacing: 0.1em;
  }
}

.amita{
  font-family: 'Amita', cursive;
}
.ubuntu{
  font-family: 'Ubuntu', sans-serif;
}

span.wpcf7-not-valid-tip {
  margin-top: 10px;
}

.wpcf7-not-valid-tip{
  display: none!important;
}

/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
pc,sp共部分
-------------------------------------*/
#TopicPath {
  text-align: left;
  ol {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    list-style: none;
    line-height: 1;
    font-family: helvetica,'arial black',arial,sans-serif;
    color: #888888;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      display: inline;
      background: url(../img/contents/arrow01.jpg) no-repeat left center;
      background-size: 18px auto;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-weight: 500;
      color: #222;
      &.home {
        padding-left: 0;
        background: none;
      }
      a {
        text-decoration: none;
        display: inline-block;
        color: #3295d6;
      }
    }
  }
}

/* アコーディオン用css */
/*#################### PC ####################*/
@media print, screen and (min-width: 768px) {
  body {
    min-width: 1000px;
    font-size: 15px;
  }
  p {
    margin: 0 0 15px;
    line-height: 32px;
    font-size: 16px;
    letter-spacing: 0.1em;
  }
  a {
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    &[href^="tel"] {
      text-decoration: none;
      &:hover {
        cursor: text;
      }
    }
    &:hover {
      text-decoration: none;
      -webkit-transition: all .4s;
      -moz-transition: all .4s;
      -ms-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s;
    }
  }
  span{
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    &:hover {
      -webkit-transition: all .4s;
      -moz-transition: all .4s;
      -ms-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s;
    }
  }
  .click {
    cursor: pointer;
  }
  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }
  /*####################　枠設定　####################*/
  #Header, #GlobalNav, #Container {
    min-width: 1000px;
  }
  #Page .innerBasic{
    width:1220px;
  }
  .innerBasic{
    width: 1000px;
    margin:0 auto;
  }
  #Footer {
    min-width: 1000px;
  }
  /*####################　ヘッダ　####################*/
  #Wall{
    display: none;
  }
  .headBar{
      width: 100%;
      height: 4px;
      background: linear-gradient( -90deg, #308dc9, #1d4ea1 );
      background-color: #1d4ea1;
      position: fixed;
      top: 0;
      display:block;
      z-index: 101;
  }
  .fixed .airBox{
    height: 160px;
  }
  .fixed #Header{
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 100;
    background-color: #fff;
    #ContBoxHeader h1{margin-top: 18px;}
    #HeaderMenu{margin-top: 25px;}
  }
  #Header{
    height: 160px;
    position: relative;
    &::after{
      content: "";
      display: block;
      clear: both;
    }
    h1{
      float: left;
      margin-top: 48px;
      margin-left: 30px;
      a{
        display: block;
        background-repeat: no-repeat;
        background-image: url(../img/header/logo.png);
        width: 229px;
        height: 65px;
        background-size: 229px auto;
        text-indent: -9999px;
      }
    }
    #HeaderMenu {
      margin-right: 30px;
      margin-top: 55px;
      float: right;
      ul{
        display: inline-block;
        font-size: 0;
        li{
          display: inline-block;
          padding-right: 23px;
          &:last-of-type{
            padding-right: 0;
          }
          a{
            font-weight: bold;
            font-size: 16px;
            color: #222222;
            &:hover{
              opacity: 0.7;
            }
            span{
            }
          }
        }
        li:nth-of-type(4){
          position: relative;
          &::after{
            content: "";
            display: inline-block;
            width: 1px;
            height: 16px;
            background-color: #ddd;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
      .headerRecruit{
        display: inline-block;
        margin-left: 25px;
        margin-right: 0;
        padding-right: 29px;
        a{
          font-weight: bold;
          font-size: 16px;
          color: #dc351f;
          padding-left: 30px;
          position: relative;
          &::before {
            content: "";
            display: block;
            background: url(../img/header/icon_03.png) 0 0 no-repeat;
            width: 22px;
            height: 24px;
            background-size: 22px auto;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
      .headerContact{
        display: inline-block;
        .btn01{
          &::before{
            left: 30px;
          }
          span{
            font-size: 14px;
            padding-left: 38px;
          }
        }
      }
    }
  }
  /*####################　グローバルナビ　####################*/
  #GlobalNav{}
  #MenuButton {
    display: none !important;
  }
  /*####################　サブメニュー　####################*/
  #Sub {}
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    width: 1000px;
    // margin: 49px 0 0 140px;
    margin: 49px auto 0 auto;
    ol li {
      padding-left: 24px;
      font-size: 14px;
      color: #888;
      a:hover {
        text-decoration: underline;
      }
    }
  }
  /*　メイン
  -------------------------------------*/
  body:not(#Page){
    #MainImg{
      height: 500px;
      position: relative;
      h2{
        text-align: center;
        text-align: -webkit-center;
        position: relative;
        top: 130px;
        &::before{
          position: relative;
          margin: auto;
          top: -12px;
        }
        span{
              display:block;
              span{
                display: inline-block;
                font-weight: bold;
          background: linear-gradient(-90deg, #308dc9, #1d4ea1);
              -webkit-background-clip: text;
              -webkit-text-fill-color: rgba(255,255,255,0.0);
              }
        }
        .span01{
          font-size: 60px;
          line-height: 85.5px;
          letter-spacing: 0.2em;
        }
        .span02{
          font-size: 28px;
          letter-spacing:0.35em;
        }
      }
    }
  }

  .titleType01{
    padding-top: 24px;
    position: relative;
    &::before{
          content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 38px;
    height: 6px;
    background: url(../img/contents/icon05.png) 0 0 no-repeat;
    background-size: 38px auto;
    }
    span{
      font-weight: bold;
    }
    .span01{
      font-size: 34px;
      line-height: 49px;
      letter-spacing: 0.18em;
      position: relative;
      left: -2px;
    }
    .span02{
      font-size: 16px;
      letter-spacing: 0.35em;
    }
  }
  /*####################　フッタ　####################*/
  #Footer{
    .footerBox01{
      height: 300px;
      background: linear-gradient(-90deg, #144497, #2283c1);
      position: relative;
      &::before{
        content: "";
        display: block;
        background: url(../img/footer/image01.png) 0 0 no-repeat;
        background-size: 1280px auto;
        width: 1280px;
        height: 300px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
      &::after{
        content: "";
        display: block;
        background: url(../img/footer/image02.png) 0 0 no-repeat;
        background-size: 194px auto;
        width: 194px;
        height: 140px;
        position: absolute;
        bottom: 0;
        right: 607px;
        left: 0;
        margin: auto;
      }
      .innerBasic{
        width: 1000px;
        padding: 63px 0;
        &::after{
          content: "";
          display: block;
          clear: both;
        }
        .footerSubBox01{
          float: left;
          margin-top: 46px;
          h3{
            span{
              color: #fff;
              display: block;
              font-weight: bold;
            }
            .span01{
              font-size: 34px;
              letter-spacing: 0.2em;
            }
            .span02{
              font-size: 16px;
              letter-spacing: 0.35em;
            }
          }
        }
        .footerSubBox02{
          float:right;
          p{
            text-align: center;
            color: #fff;
            line-height: 32px;
            margin-bottom: 29px;
            font-size: 16px;
            letter-spacing: 0.1em;
          }
          dl{
            dt{
              float:left;
              margin-right: 28px;
              span{
                color: #f2c145;
                display: block;
              }
              .span01{
                a{
                  color: #f2c145;
                  font-size: 38px;
                  padding-left: 45px;
                  position: relative;
                  letter-spacing: 0.1em;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/footer/icon01.png) 0 0 no-repeat;
                    background-size: 38px;
                    width: 38px;
                    height: 38px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 6px;
                    margin: auto;
                  }
                }
              }
              .span02{
                font-size: 12px;
                text-align: center;
                letter-spacing: 0.1em;
                position: relative;
                top: -6px;
              }
            }
            dd{
              padding-left: 29px;
              float: right;
              position: relative;
              &::before{
                content: "";
                width: 1px;
                height: 80px;
                background-color: #fff;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
              .btn01{
                width: 320px;
                height: 70px;
                line-height: 70px;
                font-size: 16px;
                border-radius: 35px;
                &:hover{
                  opacity: 0.7;
                }
                &::before{
                  width: 32px;
                  height: 24px;
                  background-size: 32px auto;
                }
                span{
                  display: inline-block;
                  margin-left: 20px;
                  letter-spacing: 0.1em;
                }
              }
            }
          }
        }
      }
    }
    .footerBox02{
      height: 210px;
      .innerBasic{
        width: 1000px;
        &::after{
          content: "";
          display: block;
          clear: both;
        }
        h2{
          float: left;
          margin-top: 50px;
          a{
            display: block;
            background-repeat: no-repeat;
            background-image: url(../img/footer/logo.png);
            width: 229px;
            height: 65px;
            background-size: 229px auto;
            text-indent: -9999px;
          }
        }
        .footerSubBox01{
          margin-top: 73px;
          float: right;
          ul{
            font-size: 0;
            li{
              display: inline-block;
              margin-right: 19px;
              &:hover{
                text-decoration: underline;
              }
              &:last-of-type{
                margin-right: 0;
              }
              a{
                font-size: 14px;
                color: #222;
                letter-spacing: 0.1em;
              }
            }
          }
          address{
            font-size: 12px;
            margin-top: 45px;
            color: #bbb;
            font-family: 'Ubuntu', sans-serif;
            text-align: right;
            letter-spacing: 0.1em;
          }
        }
      }
    }
  }
}

/*#################### SP ####################*/
@media screen and (max-width: 767px) {
  body {
    font-size: 15px;
    overflow-x: hidden;
  }
  p {
    margin: 0 0 15px;
    line-height: 32px;
    font-size: 16px;
    letter-spacing: 0.1em;
  }
  .pc_display,.disp_pc {
    display: none;
  }
  .sp_display,.disp_sp {
    display: block;
  }
  .sp_display_inline {
    display: inline;
  }
  .titleType01{
    padding-top: 29px;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 38px;
      height: 6px;
      background: url(../img/contents/icon05.png) 0 0 no-repeat;
      background-size: 38px auto;
    }
    .span01{
      position: relative;
      left: -2px;
      line-height: 36px;
      letter-spacing: .18em;
      font-size: 34px;
      font-weight: bold;
    }
    .span02{
      margin-top: 5px;
      letter-spacing: .35em;
      font-size: 16px;
      font-weight: bold;
    }
  }
  /*####################　枠設定　####################*/
  .innerBasic {
    margin: 0 15px;
  }
  /*####################　ヘッダ　####################*/
  .active #Wall {
    display: block;
  }
  #Wall{
    z-index: 10;
    display: block;
    text-indent: -9999px;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(255,255,255,.95);
    display: none;
  }
  #Header{
    z-index: 70;
    height: 70px;
    position: fixed;
    background-color: #fff;
    width: 100%;
    &::before{
      content: "";
      width: 100%;
      height: 2px;
      background-color: #1d4ea1;
      position: absolute;
      top: 0;
      display:block;
    }
    &::after{
      content: "";
      display: block;
      clear: both;
    }
    #ContBoxHeader{
      margin: 0 15px;
      h1{
        padding-top: 13px;
        a{
          display: block;
          background-repeat: no-repeat;
          background-image: url(../img/header/logo.png);
          width: 153px;
          height: 43px;
          background-size: 153px auto;
          text-indent: -9999px;
        }
      }
    }
  }
  .airBox{
    height: 70px;
  }
  /*####################　グローバルナビ　####################*/
  #GlobalNav{
    display:none;
  }
  #MenuButton {
    cursor: pointer;
    position: absolute;
    z-index: 60;
    top: 27px;
    right: 16px;
    box-sizing: border-box;
    width: 25px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/header/icon_01.png);
    background-size: 25px auto;
    text-indent: -9999px;
  }
  .active #MenuButton {
    background-position: center;
    background-image: url(../img/header/icon_02.png);
    background-size: 20px auto;
  }
  #HeaderMenu_sp {
    position: fixed;
    right: 0;
    z-index: 50;
    top: -550px;
    width: 100%;
    height: 490px;
    text-align: center;
    transition: top 0.5s;
    background-color: #fff;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all 5s;
    -o-transition: all .5s;
    transition: all .5s;
    ul{
      li{
        margin-bottom: 26px;
        a{
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.1em;
          display: inline-block;
        }
        &:nth-of-type(4){
          position: relative;
          margin-bottom: 30px;
          padding-bottom: 26px;
          &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: inline-block;
            margin: auto;
            width: 43px;
            height: 1px;
            background-color: #ddd;
          }
        }
      }
      li.headerRecruit{
        display: inline-block;
        margin-bottom: 29px;
        a{
          position: relative;
          padding-left: 30px;
          font-size: 16px;
          font-weight: bold;
          color: #dc351f;
          &::before{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: block;
            margin: auto;
            width: 22px;
            height: 24px;
            background: url(../img/header/icon_03.png) 0 0 no-repeat;
            background-size: 22px auto;
          }
        }
      }
      li.headerContact{
        a{
          font-size: 14px;
          margin: auto;
          color: #fff;
          &::before{
            left: 30px;
          }
          span{
            padding-left: 36px;
          }
        }
      }
    }
  }
  .active #HeaderMenu_sp{
    display: block;
    top: 68px;
    z-index: 11;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all 5s;
    -o-transition: all .5s;
    transition: all .5s;
  }
  .active h1{
    display: none;
  }
  /*####################　サブメニュー　####################*/
  #Sub {}
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    margin: 42px auto 0;
    padding: 0 px;
    ol{ 
      margin: 0 15px;
      li {
        padding-left: 22px;
        font-size: 12px;
        color: #888;
        a{
          text-decoration: underline;
          color: #3295d6;
        }
      }
    }
  }
  #PageNews{
    #TopicPath {
    ol{
      li {
        margin-right: 5px;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
  }
  /*　メイン
  -------------------------------------*/
  body:not(#Page){
    #MainImg{
      height: 250px;
      position: relative;
      h2{
        text-align: center;
        text-align: -webkit-center;
        position: relative;
        top: 46px;
        &::before{
          position: relative;
          top: -6px;
        }
        span{
          font-weight: bold;
          background: linear-gradient(-90deg, #308dc9, #1d4ea1);
              -webkit-background-clip: text;
              -webkit-text-fill-color: rgba(255,255,255,0.0);
              display: table;
        }
        .span01{
          font-size: 35px;
          letter-spacing: 0.2em;
        }
        .span02{
          font-size: 18px;
          letter-spacing:0.35em;
        }
      }
    }
  }
  /*####################　フッタ　####################*/
  #Footer{
    .footerBox01{
      height: 480px;
      background: linear-gradient(-90deg, #144497, #2283c1);
      position: relative;
      &::before{
        content: "";
        display: block;
        background: url(../img/footer/image01_sp.png) bottom no-repeat;
        background-size: 100% auto;
        height: 480px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
      &::after{
        content: "";
        display: block;
        background: url(../img/footer/image02.png) 0 0 no-repeat;
        background-size: 194px auto;
        width: 194px;
        height: 140px;
        position: absolute;
        top: -140px;
        right: 0;
      }
      .innerBasic{
        margin: 0 15px;
        padding: 45px 0;
        &::after{
          content: "";
          display: block;
          clear: both;
        }
        .footerSubBox01{
          margin-bottom: 17px;
          h3{
            span{
              color: #fff;
              display: block;
              font-weight: bold;
            }
            .span01{
              font-size: 34px;
              letter-spacing: 0.2em;
            }
            .span02{
              font-size: 16px;
              letter-spacing: 0.35em;
            }
          }
        }
        .footerSubBox02{
          p{
            font-size: 16px;
            color: #fff;
            line-height: 28px;
            margin-bottom: 24px;
          }
          dl{
            text-align: center;
            dt{
              display: inline-block;
              span{
                color: #f2c145;
                display: block;
              }
              .span01{
                position: relative;
                &::after{
                  content: "";
                  height: 1px;
                  background-color: #f2c146;
                  width: 270px;
                  position: absolute;
                  bottom: 8px;
                  left: 48px;
                  right: 0;
                  margin: auto;
                }
                a{
                  color: #f2c145;
                  font-size: 38px;
                  padding-left: 50px;
                  position: relative;
                  letter-spacing: 0.1em;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/footer/icon01.png) 0 0 no-repeat;
                    background-size: 38px;
                    width: 38px;
                    height: 38px;
                    position: absolute;
                    left: 5px;
                    top: 0;
                    bottom: 5px;
                    margin: auto;
                  }
                }
              }
              .span02{
                font-size: 12px;
                text-align: center;
                letter-spacing: 0.1em;
                position: relative;
                top: -2px;
              }
            }
            dd{
              position: relative;
              margin-top: 14px;
              .btn01{
                max-width: 320px;
                width: 100%;
                height: 70px;
                line-height: 70px;
                font-size: 16px;
                border-radius: 35px;
                margin: auto;
                &::before{
                  width: 32px;
                  height: 24px;
                  background-size: 32px auto;
                }
                span{
                  display: inline-block;
                  margin-left: 20px;
                  letter-spacing: 0.1em;
                }
              }
            }
          }
        }
      }
    }
    .footerBox02{
      height: 204px;
      padding-top: 45px;
      .innerBasic{
        &::after{
          content: "";
          display: block;
          clear: both;
        }
        h2{
          text-align: center;
          a{
            display: inline-block;
            background-repeat: no-repeat;
            background-image: url(../img/footer/logo.png);
            width: 153px;
            height: 43px;
            background-size: 153px auto;
            text-indent: -9999px;
          }
        }
        .footerSubBox01{
          margin-top: 15px;
          ul{
            text-align: center;
            font-size: 0;
            li{
              display: inline-block;
              margin-right: 20px;
              &:last-of-type{
                margin-right: 0;
              }
              a{
                font-size: 14px;
                color: #222;
                letter-spacing: 0.1em;
              }
            }
            li.disp_pc{
              display: none;
            }
          }
          address{
            text-align: center;
            font-size: 12px;
            margin-top: 20px;
            color: #bbb;
            font-family: 'Ubuntu', sans-serif;
            letter-spacing: 0.1em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 374px) { //320px端末向け
  #Footer .footerBox01 .innerBasic .footerSubBox02 dl dt .span01 a{
    letter-spacing: 0.01em;
  }
  #Footer .footerBox01 .innerBasic .footerSubBox02 dl dt .span01::after{
    width: auto;
  }
  #Footer .footerBox01 .innerBasic .footerSubBox02 dl dd .btn01::before{
    left: 30px;
  }
}

/*end*/